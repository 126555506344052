import React from "react";
import { projectsLeft, projectsRight } from '../data.js';
import ProjectItem from './projectItem';

const Projects = () => {
    return (
        <div className="projects-container">
            <div claaName="left">
                {projectsLeft.length > 0 ? (
                    projectsLeft.map((project) => (
                        <ProjectItem
                            key={project.image}
                            project={project} // Pass the project data correctly
                        />
                    ))
                ) : (
                    <div>No projects available</div>
                )}
            </div>
            <div className="right">
                {projectsRight.length > 0 ? (
                    projectsRight.map((project) => (
                        <ProjectItem
                            key={project.image}
                            project={project} // Pass the project data correctly
                        />
                    ))
                ) : (
                    <div>No projects available</div>
                )}
            </div>
        </div>
    );
};

export default Projects;
