import React, { useEffect, useRef } from 'react';
import imagesLoaded from 'imagesloaded';

const ProjectItem = ({ project = {} }) => {
    const itemRef = useRef(null);

    useEffect(() => {
        const handleIntersection = () => {
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            entry.target.classList.add('visible');
                        }
                    });
                },
                { threshold: 0.4 } // Trigger when 10% of the element is visible
            );

            if (itemRef.current) {
                observer.observe(itemRef.current);
            }

            return () => {
                if (itemRef.current) {
                    observer.unobserve(itemRef.current);
                }
            };
        };

        // Wait for images to load before activating the intersection observer
        if (itemRef.current) {
            imagesLoaded(itemRef.current, { background: true }, () => {
                handleIntersection(); // Start observing once images have loaded
            });
        }

        return () => {
            // Cleanup the observer
            if (itemRef.current) {
                imagesLoaded(itemRef.current).off('done', handleIntersection);
            }
        };
    }, []);

    if (!project || Object.keys(project).length === 0) {
        return <div>No project available</div>;
    }

    return (
        <a
            href={project.link}
            target="_blank"
            rel="noopener noreferrer"
            className="project-item"
            ref={itemRef} // Attach the ref to the element to observe
        >
            <div className="img-container">
                <img
                    src={project.image}
                    loading="lazy" // Native lazy loading
                    className="w-100"
                    alt={project.company}
                    />
            </div>
            <div className="text">
                <h2 className="title">{project.company}</h2>
                <span>{project.description}</span>
            </div>
        </a>
    );
};

export default ProjectItem;
