import React from "react";

const CoverBlock = () => {
    return (
        <div className="intro">
            <div className="row">
                <div className="title">
                    <div className="d-block d-lg-flex justify-content-lg-between w-100">
                        <h1>Subscription based</h1>
                        <h1 className="d-block d-lg-none">Design agency</h1>
                        <a href="mailto:jonni@smorre.is" className="d-none d-lg-block"><h1>Let's chat</h1></a>
                    </div>
                    <h1 className="d-none d-lg-block">Design agency</h1>
                </div>
            </div>
        </div>
    );
};

export default CoverBlock;