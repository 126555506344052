export const projectsLeft = [
    {
        image: "/images/abler.png",
        company: "Abler",
        description: "Services included website, app, social media and keynote design",
        link: "https://www.abler.io/home/en/",
    },
    {
        image: "/images/onefootball.png",
        company: "Onefootball",
        description: "Services included app design",
        link: "https://onefootball.com",
    },
    {
        image: "/images/annaeiriks.png",
        company: "Anna Eiríks",
        description: "Services included corporate logo, website and customer portal design",
        link: "https://annaeiriks.is",
    },
    {
        image: "/images/sandg-1.png",
        company: "Samuel & Gunnar",
        description: "Design for website",
        link: "https://samuelandgunnar.com",
    },
    {
        image: "/images/audur.png",
        company: "Auður",
        description: "Design for website",
        link: "https://audur.is",
    }
]

export const projectsRight = [
    {
        image: "/images/outcome-new-1.png",
        company: "Outcome",
        description: "Services included website, web app and keynote design",
        link: "https://outcome.io",
    },
    {
        image: "/images/fjarthjalfun.png",
        company: "Fjarþjálfun",
        description: "Services included corporate logo, website and social media design",
        link: "https://www.fjarthjalfun.is/",
    },
    {
        image: "/images/skot.png",
        company: "Skot",
        description: "Services included website design",
        link: "https://skot.is",
    },
    {
        image: "/images/sothebys.png",
        company: "SOTHEBY’S",
        description: "Design for app, website, social media",
        link: "https://sothebys.com",
    },
    {
        image: "/images/blokast.png",
        company: "Sýn",
        description: "Design for app, website, social media",
        link: "https://syn.is",
    }
]
