import React from "react";
import './App.scss';
import Intro from '../src/components/intro.js';
import Projects from '../src/components/projects.js';

function App() {
  return (
    <>
     <header className="App-header d-flex align-items-lg-center justify-content-between">
        <div className="logo">smørre</div>
        <nav className="">
          <ul className="links-container d-flex p-0 m-0 flex-column flex-md-row">
            <li className="links-item"><a href="https://dribbble.com/kastaniubrunn" className="link active">Dribbble</a></li>
            <li className="links-item"><a href="https://www.linkedin.com/in/kastaniubrunn/" className="link">LinkedIn</a></li>
            <li className="links-item"><a href="https://x.com/kastaniubrunn" className="link">X</a></li>
            <li className="links-item"><a href="https://www.instagram.com/kastaniubrunn/" className="link">Instagram</a></li>
          </ul>
        </nav>
    </header>
    <section className="App p-0">
      <Intro />
      <Projects />
    </section>
      <footer>
        <a href="mailto:jonni@smorre.is" className="d-block d-lg-none">
          <h1>Let's chat</h1>
        </a>
        <span>2024</span>
    </footer>
    </>
  );
}

export default App;
